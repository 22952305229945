<template>
  <div>
    <v-container>
      <v-row justify="center" align="center" style="height: 500px">
        <v-card :loading="loading" outlined>
          <v-card-title
            class="d-flex justify-center text-center flex-column pb-10"
          >
            <div class="d-flex justify-center text-center flex-column">
              <v-img
                contain
                position="center"
                src="../../assets/scode field.png"
                height="100"
                width="200"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <v-form
              :disabled="loading"
              v-model="valid"
              lazy-validation
              ref="login"
              @submit.prevent="login"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    v-model="username"
                    :label="$t('email')"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    :error="error"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    v-model="password"
                    :type="isPasswordShow ? 'text' : 'password'"
                    :append-icon="isPasswordShow ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="isPasswordShow = !isPasswordShow"
                    :label="$t('password')"
                    :error="error"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-text-field>
                  <h3 class="error--text ml-2" v-if="error">
                    {{ $t("invaild-username-or-password") }}
                  </h3>
                </v-col>
                <v-col cols="12"> </v-col>
              </v-row>

              <div class="d-flex justify-end">
                <v-btn
                  block
                  :loading="loading"
                  :disabled="!username && !password"
                  x-large
                  type="submit"
                  color="primary"
                  >{{ $t("login") }}</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card></v-row
      >
    </v-container>
  </div>
</template>
<script>
import useJwt from "@/libs/auth/useJwt";

export default {
  components: {},

  methods: {
    login() {
      if (this.$refs.login.validate()) {
        this.loading = true;
        useJwt
          .login({ userNameOrEmail: this.username, password: this.password })
          .then((res) => {
            let result = res.data.result;
            switch (result.roles[0]) {
              case "Owner":
              case "Supervisor":
              case "Manager":
              case "SalesManager":
              case "SalesTeamLeader":
                console.log("ok");
                if (res.status == 200) {
                  if (this.unAuth.indexOf(result.roles[0]) == -1) {
                    this.error = false;
                    localStorage.setItem("userInfo", JSON.stringify(result));
                    localStorage.setItem("accessToken", result.accessToken);
                    //
                    if (result.userData.companyId != null) {
                      this.$http
                        .get("/companies/" + result.userData.companyId)
                        .then((res) => {
                          this.$store.commit("UPDATE_COMPANY", res.data.result);
                          // this.$router.push({ name:"dashboard-company" })
                          this.$router.go({ name: "dashboard-company" });
                        });
                    } else {
                      this.$router.go({ path: "/" });
                    }
                  } else {
                    this.error = true;
                    this.loading = false;
                  }
                } else {
                  this.error = true;
                }
                break;

              default:
                this.$store.state.snackbarMessage = this.$t(
                  "you-are-unauthorized"
                );
                this.$store.state.snackbar = true;
                // this.$service.showError("err");
                break;
            }
          })
          .catch((e) => {
            this.error = true;
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  data() {
    return {
      valid: true,
      error: false,
      username: "",
      loading: false,
      password: "",
      isPasswordShow: false,
      unAuth: ["Admin"],
    };
  },
};
</script>
<style lang="scss"></style>
